<template>
  <div class="sentiment-detail">
    <div class="sentiment-detail-left">
      <div class="sentiment-detail-top">
        <!-- <img :src="require('@/assets/imgs/i8.png')" alt="" /> -->
        <img v-if="text.ico" :src="text.ico" alt="" />
        <!-- <span>{{ text.websiteName }}</span> -->
        <span>
          原文地址：
          <a :href="text.url" target="_blank">{{ text.url }}</a>
        </span>
      </div>
      <div class="sentiment-detail-title">
        <div class="title-top">{{ text.title }}</div>
        <div class="title-info">
          <span>
            {{ text.issuingTime }} {{ text.websiteName }} / {{ text.author }}
          </span>
          <span class="span-2">标签：{{ text.tag }}</span>
        </div>
      </div>
      <div class="sentiment-detail-text" v-html="text.text"></div>
      <div style="height:50px; widht:30px"></div>
    </div>
    <div class="sentiment-detail-right">
      <div class="sentiment-detail-top">近8条查看记录</div>
      <div class="sentiment-detail-content">
        <div class="right-item" v-for="(item, i) in listRetrieval" :key="i">
          <div
            class="right-item-left"
            :class="logIndex == i ? 'select-log' : ''"
          >
            {{ i + 1 }}
          </div>
          <div class="right-item-right">
            <div
              class="right-item-right-text"
              :class="logIndex == i ? 'select-log' : ''"
            >
              <span @click="lookNews(item, i)">{{ item.title }}</span>
            </div>
            <div class="right-item-bottom">{{ item.websiteName }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { articleDetail, readSearch } from '@/api/sentiment.js'
export default {
  data() {
    return {
      text: {},
      listRetrieval: [],
      logIndex: null
    }
  },
  created() {
    let id = this.$route.params.newsId
    this.getlogSearch()
    if (id) {
      this.getData(id)
    } else {
      this.$router.go(-1)
    }
  },
  methods: {
    // 查看记录
    getlogSearch() {
      readSearch().then(res => {
        this.listRetrieval = res
      })
    },
    getData(id) {
      articleDetail(id).then(res => {
        this.text = Object.assign({}, res)
      })
    },
    lookNews(row, index) {
      this.getData(row.articleId)
      this.logIndex = index
    }
  }
}
</script>

<style lang="scss" scoped>
.sentiment-detail {
  width: 100%;
  height: calc(100vh - 96px);
  background: rgb(255, 255, 255);
  overflow: auto;
  // display: flex;
  // justify-content: space-between;
  .sentiment-detail-left {
    width: 70%;
    height: 100%;
    padding: 20px 20px;
    float: left;
    // overflow: auto;
    .sentiment-detail-top {
      display: flex;
      justify-content: space-between;
      padding-bottom: 10px;
      border-bottom: 1px solid rgb(212, 208, 208);
      padding: 10px 50px;
      img {
        width: auto;
        height: 40px;
        object-fit: cover;
        align-self: center;
      }
      span {
        align-self: center;
        font-size: 14px;

        font-weight: 400;
        color: #777777;
        a {
          font-size: 14px;

          font-weight: 400;
          color: #777777;
        }
      }
    }

    .sentiment-detail-title {
      margin: 30px 50px;
      .title-top {
        font-size: 24px;
        font-weight: 400;
        color: #070707;
      }
      .title-info {
        height: 14px;
        font-size: 14px;
        font-weight: 400;
        color: #777777;
        margin-top: 15px;
        .span-2 {
          margin-left: 40px;
        }
      }
    }
    .sentiment-detail-text {
      font-size: 16px;
      font-weight: 400;
      color: #060606;
      line-height: 30px;
      margin: 0 50px;
    }
  }
  .sentiment-detail-right {
    width: 29.5%;
    height: 100%;
    padding: 30px 100px;
    float: right;

    .sentiment-detail-top {
      font-size: 16px;
      font-weight: 400;
      color: #131313;
      padding-bottom: 10px;
      border-bottom: 1px solid rgb(212, 208, 208);
      position: relative;
    }
    .sentiment-detail-top::after {
      content: '';
      width: 130px;
      height: 3px;
      position: absolute;
      bottom: 0;
      left: 0;
      background: #694638;
    }
    .sentiment-detail-content {
      width: 100%;
      height: calc(100% - 50px);
      // overflow: auto;
    }
    .right-item {
      display: flex;
      margin: 13px 0;
      border-bottom: 1px solid #dad8d8;
      &:nth-last-child(1) {
        border-bottom: 0;
      }
      .right-item-left {
        font-size: 26px;
        font-weight: 400;
        color: #694638;
      }
      .right-item-right {
        margin-left: 10px;
      }
      .right-item-right-text {
        font-size: 14px;
        font-weight: bold;
        color: #131313;
        line-height: 24px;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        span {
          cursor: pointer;
          &:hover {
            color: #1188f8;
          }
        }
      }
      .select-log {
        color: #1188f8;
      }
      .right-item-bottom {
        font-size: 12px;
        padding: 15px 0;
        font-weight: 400;
        color: #6d6c6b;
      }
    }
  }
}
</style>
